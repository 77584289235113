import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Frequently Asked Questions (FAQ)">
    <section className="section-faq">



    </section>
  </Layout>
);

export default Page;

/*
<!--
            ion-ios-star-outline
            <span className="icon ion-social-googleplus-outline"></span> -->
 */